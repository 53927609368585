import React from 'react'

import { Button as MaterialButton } from '@material-ui/core'

export const Button = ({
  children,
  color, // primary, secondary, terciary - by default every button is primary
  component, // elementType
  disabled,
  disableElevation,
  disableFocusRipple,
  disableRipple,
  endIcon,
  fullWidth,
  href,
  onClick,
  size, // large, medium, small
  startIcon,
  type,
  variant, // contained, outlined, text - by default every button is contained
  }) => {
    const getColor = () => {
      let colorProperty = color || 'primary'
      if (color === 'terciary') {
        colorProperty = 'secondary'
      }
      return colorProperty
    }

    const getClasses = () => {
      let classesProperty = {}
      if (color === 'terciary') {
        classesProperty = {
          root: 'terciary'
        }
      }
      return classesProperty
    }

    return (
      <div className="CommonButtonContainer">
        <MaterialButton
          color={getColor()}
          classes={getClasses()}
          component={component || 'button'}
          disabled={disabled}
          disableElevation={disableElevation}
          disableFocusRipple={disableFocusRipple}
          disableRipple={disableRipple}
          endIcon={endIcon}
          fullWidth={fullWidth}
          href={href}
          onClick={onClick}
          size={size}
          startIcon={startIcon}
          type={type || 'button'}
          variant={variant || 'contained'}
        >
          {children}
        </MaterialButton>
      </div>
    )
}

export default Button
