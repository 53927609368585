import React from 'react'

const useModal = () => {
  let [modal, setModal] = React.useState(false)
  let [modalContent, setModalContent] = React.useState('Im the Modal Content')
  let [modalW, setModalW] = React.useState(400)
  let [closeSymbol, setCloseSymbol] = React.useState(false)

  let handleModal = (content = false, width, closeSymbol) => {
    setModal(!modal)
    if (content) {
      setModalContent(content)
    }
    if (width) {
      setModalW(width)
    }

    if (closeSymbol) {
      setCloseSymbol(closeSymbol)
    }
  }

  return { modal, handleModal, modalContent, modalW, closeSymbol }
}

export default useModal
