import React from 'react'
import LinkNext from 'next/link'
import PropTypes from 'prop-types'
import { withTranslation } from '@utils/i18n'

import Modal from '@components/Modal'
import Link from '@components/common/Link'
import PdfViewer from '@components/PdfViewer'

import css from './footer.module.scss'

const Footer = ({ t }) => {
  return (
    <footer className={css.footer}>
      <nav>
        <menu>
          <Modal
            actionText={<Link>{t('tac_link')}</Link>}
            width={800}
            content={<PdfViewer doc="/docs/tyc.pdf"/>}
            title={t('tyc')}
            closeButton={t('close')}
            closeSymbol
          />          
          <Modal
            actionText={<Link>{t('privacity_link')}</Link>}
            width={800}
            content={<PdfViewer doc="/docs/privacidad.pdf"/>}
            title={t('privacy')}
            closeButton={t('close')}
            closeSymbol
          />
          <LinkNext href="faq">
            <Link>{t('faq_link')}</Link>
          </LinkNext>
        </menu>
      </nav>
    </footer>
  )
}

Footer.getInitialProps = async () => ({
	namespacesRequired: ['common']
})

Footer.propTypes = {
	t: PropTypes.func.isRequired
}

export default withTranslation('common')(Footer)
