import React, { useEffect, useState } from 'react'

import { ModalContext } from './modalContext'

import { Modal as MaterialModal } from '@material-ui/core/'

import css from './index.module.scss'

const CLOSE_PATH = `/images/close.png`
const closeImg = <img src={CLOSE_PATH} />

const Modal = () => {
  let { modalContent, handleModal, modal, modalW, closeSymbol } = React.useContext(ModalContext)
  const [modalStyle, setModalStyle] = useState({})

  useEffect(() => {
    getModalStyle()
    window.addEventListener("resize", getModalStyle)
    return () => { window.removeEventListener("resize", getModalStyle) }
  }, [modal])

  const getModalStyle = () => {
    const widthx = modalW
    const pageWidth = window.innerWidth
    const calculatedLeft = Number(pageWidth / 2) - (widthx / 2)
    const left = calculatedLeft > 0 ? `${calculatedLeft}px` : '5%'
    setModalStyle({
      top: `15%`,
      left: `${left}`,
      width: widthx,
      maxHeight: '80vh',
    })
  }

  if (modal) {
    return (
      <MaterialModal
        open={modal}
        onClose={handleModal}
        BackdropProps= {{
          style: {backgroundColor: 'rgba(255, 255, 255, 0.8)'}
        }}
      >
        <div style={modalStyle} className={css.container}>
          {closeSymbol && <div className={css.closeSymbolContainer} onClick={() => handleModal()}>{closeImg}</div>}
          {/* {(closeSymbol) &&
            <div className={css.heading}>
              {closeSymbol && <div className={css.closeSymbolContainer} onClick={handleModal}>{closeImg}</div>}}
            </div>
          } */}
          <div className={css.modalContent}>{modalContent}</div>
        </div>
      </MaterialModal>
    )
  } else return null
}

export default Modal
