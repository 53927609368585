export const BASIC_FIELD_TYPES = ['text', 'email', 'number', 'password']
export const DROPDOWN_FIELD_TYPES = ['select']
export const FILE_FIELD_TYPES = ['file']
export const ACCEPTED_FILE_TYPES = 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*'

export const isFieldValid = (type, required, value) => {
  // return true
  if (required && !value) return false

  if (type === 'email') {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(value.toLowerCase())
  }
  if (type === 'password') {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/
    return re.test(value)
  }
  if (type === 'name') {
    return !!(value.length >= 5 && value.split(' ') > 1)
  }
  if(type === 'swift') {
    return !!(value.length >= 8 && value.length <= 11)
  }
  if(type === 'iban') {
    return !!(value.length >= 4 && value.length <= 34)
  }
  return true
}