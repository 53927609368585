import React from 'react'
import LinkNext from 'next/link'
import classNames from 'classnames'

import Navbar from '@components/Navbar'
import Link from '@components/common/Link'

import css from './index.module.scss'

export const Header = ({ className }) => {
  return (
    <header className={classNames(css.header, className)}>
      <LinkNext href="/">
        <Link underline="none">
          <img src={`/images/logo.png`} className={css.logo}/>
        </Link>
      </LinkNext>
      <Navbar/>
    </header>
  )
}

export default Header
