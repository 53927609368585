import React, { useState, useEffect, useContext, createContext } from 'react'
import moment from 'moment'

import { getTRM } from '@services/api'

const globalContext = createContext()

export function ProvideGlobal({ children }) {
  const global = useProvideGlobal()
  return <globalContext.Provider value={global}>{children}</globalContext.Provider>
}

export const useGlobal = () => {
  return useContext(globalContext)
}

function useProvideGlobal() {
  const [TRM, setTRM] = useState(0)

  useEffect(async () => {
    if (!TRM) {
      let date = getDate()
      let cont = 0
      let TRMResult = await getTRM(date)
      let TRMValue = TRMResult[0]?.valor
      while (!TRMValue && cont <= 4) {
        cont++
        date = substractOneDay(date)
        TRMResult = await getTRM(date)
        TRMValue = TRMResult[0]?.valor
      }
      setTRM(TRMValue)
    }
  }, [])

  const substractOneDay = (date) => {
    return moment(date, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD')
  }

  const getDate = () => {
    const dateObj = new Date()
    const month = dateObj.getMonth() + 1
    const day = dateObj.getDate()
    const year = dateObj.getFullYear()
    const date = `${year}-${month}-${day}`
    return date
  }

  return {
    TRM,
  }
}
