import React from 'react'

import Typography from '@material-ui/core/Typography'

const Text = ({
  align, // inherit, left, center, right, justify
  children,
  color, // initial, inherit, primary(gray), secondary(dark gray), terciary(white), textPrimary(green), textSecondary(orangr), error(red)
  component, // Either a string to use a HTML element
  display, // initial, block, inline
  gutterBottom,
  noWrap,
  paragraph,
  variant, // h1, h2, h3, h4, h5, h6, subtitle1, subtitle2, body1, body2, caption, button, overline, srOnly, inherit
}) => {
  const getColor = () => {
    let colorProperty = color || 'primary'
    if (color === 'terciary') {
      colorProperty = 'secondary'
    }
    return colorProperty
  }

  const getClasses = () => {
    let classesProperty = {}
    if (color === 'terciary') {
      classesProperty = {
        root: 'terciary'
      }
    }
    return classesProperty
  }

  return (
    <div className="commonTextContainer">
      <Typography
        align={align}
        color={getColor()}
        classes={getClasses()}
        component={component}
        display={display}
        gutterBottom={gutterBottom}
        noWrap={noWrap}
        paragraph={paragraph}
        variant={variant}
      >
        {children}
      </Typography>
    </div>
  )
}

export default Text
