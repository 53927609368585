import React, { useState, useEffect } from 'react'
import LinkNext from 'next/link'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useRouter } from 'next/router'

import { getUserPermissions } from '@services/api'

import { useAuth } from '@utils/auth'
import { withTranslation } from '@utils/i18n'
import { pList, pListArray } from '@utils/common'

import Header from '@components/Header'
import Link from '@components/common/Link'
import Text from '@components/common/Text'

import Tooltip from '@material-ui/core/Tooltip'
import PagesIcon from '@material-ui/icons/Pages'
import ReportIcon from '@material-ui/icons/Report'
import PersonIcon from '@material-ui/icons/Person'
import BankIcon from '@material-ui/icons/AccountBalance'
import PaymentPageIcon from '@material-ui/icons/Payment'
import DocumentTypeIcon from '@material-ui/icons/NoteAdd'
import UserCircleIcon from '@material-ui/icons/AccountCircle'
import RequireDocumentIcon from '@material-ui/icons/Description'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrowRounded'
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded'

import css from './admin-layout.module.scss'

const AdminLayout = ({ t, children }) => {
  const router = useRouter()
  const { user, getIdToken } = useAuth()
  const [collapsed, setCollapsed] = useState(false)
  const [userPermissions, setUserPermissions] = useState([])


  useEffect(() => {
    if (window.innerWidth <= 767) {
      setCollapsed(true)
    }
  }, [])

  useEffect(async () => {
    if (user && !user.is_admin) {
      router.push('/')
    }

    if (user === false) {
      router.push('/')
    }

    if (user && user.is_admin) {
      getUserPermissionsList()
    }
  }, [user])

  const getUserPermissionsList = async () => {
    const token = await getIdToken()
    const userP = await getUserPermissions(user.user.id, token)
    setUserPermissions(userP.results)
  }

  const hasPermissionToModule = (module) => {
    let hasPermission = false
    const permission = userPermissions.find(userPermission => userPermission.permission_code === module)
    if (permission?.create) {
      hasPermission = true
    }
    return hasPermission
  }

  if (user && user.is_admin && userPermissions.length) {
    return (
      <>
        <div className={css.topContainer}>
          <Header className={css.adminHeader} />
          <div className={css.mainContentContainer}>
            <div className={classNames(css.mainMenu, { [css.collapsed]: collapsed })}>
              <div className={css.mainMenuOptions}>
                {hasPermissionToModule('TD') &&
                  <div className={css.menuItemContainer}>
                    <LinkNext href="/admin/docs-types">
                      <Link underline="none">
                        <Tooltip title={t('docs_types')}>
                          <DocumentTypeIcon />
                        </Tooltip>
                        {t('docs_types')}
                      </Link>
                    </LinkNext>
                  </div>
                }
                {hasPermissionToModule('BA') &&
                  <div className={css.menuItemContainer}>
                    <LinkNext href="/admin/banks">
                      <Link underline="none">
                        <Tooltip title={t('banks')}>
                          <BankIcon />
                        </Tooltip>
                        {t('banks')}
                      </Link>
                    </LinkNext>
                  </div>
                }
                {hasPermissionToModule('LD') &&
                  <div className={css.menuItemContainer}>
                    <LinkNext href="/admin/legal-documents">
                      <Link underline="none">
                        <Tooltip title={t('require_documents')}>
                          <RequireDocumentIcon />
                        </Tooltip>
                        {t('require_documents')}
                      </Link>
                    </LinkNext>
                  </div>
                }
                {hasPermissionToModule('AP') &&
                  <div className={css.menuItemContainer}>
                    <LinkNext href="/admin/authorized-pages">
                      <Link underline="none">
                        <Tooltip title={t('autorized_pages')}>
                          <PagesIcon />
                        </Tooltip>
                        {t('autorized_pages')}
                      </Link>
                    </LinkNext>
                  </div>
                }
                {hasPermissionToModule('PR') &&
                  <div className={css.menuItemContainer}>
                    <LinkNext href="/admin/profiles">
                      <Link underline="none">
                        <Tooltip title={t('profiles')}>
                          <PersonIcon />
                        </Tooltip>
                        {t('profiles')}
                      </Link>
                    </LinkNext>
                  </div>
                }
                {hasPermissionToModule('US') &&
                  <div className={css.menuItemContainer}>
                    <LinkNext href="/admin/users">
                      <Link underline="none">
                        <Tooltip title={t('users')}>
                          <UserCircleIcon />
                        </Tooltip>
                        {t('users')}
                      </Link>
                    </LinkNext>
                  </div>
                }
                {hasPermissionToModule('RE') &&
                  <div className={css.menuItemContainer}>
                    <LinkNext href="/admin/reports">
                      <Link underline="none">
                        <Tooltip title={t('reports')}>
                          <ReportIcon />
                        </Tooltip>
                        {t('reports')}
                      </Link>
                    </LinkNext>
                  </div>
                }
                {hasPermissionToModule('PP') &&
                  <div className={css.menuItemContainer}>
                    <LinkNext href="/admin/partners-pages">
                      <Link underline="none">
                        <Tooltip title={t('partners_pages')}>
                          <PaymentPageIcon />
                        </Tooltip>
                        {t('partners_pages')}
                      </Link>
                    </LinkNext>
                  </div>
                }
                {hasPermissionToModule('TR') &&
                  <div className={css.menuItemContainer}>
                    <LinkNext href="/admin/trm-rate">
                      <Link underline="none">
                        <Tooltip title={t('trm_rate')}>
                          <AttachMoneyRoundedIcon />
                        </Tooltip>
                        {t('trm_rate')}
                      </Link>
                    </LinkNext>
                  </div>
                }
              </div>
              <div className={css.contentCollapse} onClick={() => setCollapsed(!collapsed)}>
                <DoubleArrowIcon />
                <Text>{t('collapse')}</Text>
              </div>
            </div>
            <div className={css.contentBody}>{children}</div>
          </div>
        </div>
      </>
    )
  } else {
    return null
  }
}

AdminLayout.getInitialProps = async () => ({
  namespacesRequired: ['common']
})

AdminLayout.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation('common')(AdminLayout)

