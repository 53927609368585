import React from 'react'

import { Link as MaterialLink } from '@material-ui/core'

class Link extends React.Component {
  constructor(props) {
    super(props)
  }

  render () {
    const {
      children,
      classes,
      color, // initial, inherit, primary, secondary, textPrimary, textSecondary, error
      component, //HTML tag
      href,
      onClick,
      target,
      TypographyClasses,
      underline, // none, hover, always
      variant // Applies the theme typography styles.
    } = this.props
    return (
      <div className="commonLinkContainer">
        <MaterialLink
          classes={classes}
          color={color}
          component={component}
          href={href}
          onClick={onClick}
          TypographyClasses={TypographyClasses}
          underline={underline}
          target={target}
          variant={variant}
        >
          {children}
        </MaterialLink>
      </div>
    )
  }
}

export default Link