import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
/* import NProgress from 'nprogress' */
import Router, { useRouter } from 'next/router'

import Head from 'next/head'

import Layout from '@components/Layout'
import Loader from '@components/Loader'

import { ProvideAuth } from '@utils/auth'
import { ProvideGlobal } from '@utils/global'
import { appWithTranslation } from '@utils/i18n'
import { ModalProvider } from '@utils/modalContext'

/* import 'nprogress/nprogress.css' */
import '@components/Loader/loader.css'
import '@components/common/Link/link.css'
import '@components/common/Text/text.css'
import '@components/common/Input/input.css'
import '@components/DataTable/dataTable.css'
import '@components/common/Button/button.css'

import '@styles/common.scss'

// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://203e2da494df4959aa158a1abb238eb7@o558299.ingest.sentry.io/5691608",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
function MyApp(props) {
  const router = useRouter()
  const { Component, pageProps } = props
  const [ap, setAp] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }

    if (ap === 0) {
      let authorized_parameter = router?.query?.r === 'Twc4BM0n2Ppu' ? router.query.r : false
      authorized_parameter = router?.query?.r === 'TwcAu0M0n2Ppu' ? router.query.r : authorized_parameter
      setAp(document.referrer || authorized_parameter || 'na')
      sessionStorage.setItem('ap', document.referrer || authorized_parameter || 'na')
    }
  })

  useEffect(() => {
    const start = () => setLoading(true)
    const end = () => setLoading(false)
    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)
    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
  }, [])
  if (ap !== 0) {
    return (
      <>
        <ProvideGlobal>
          <ProvideAuth>
            <ModalProvider>
              <Head>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                <link rel="icon" href="/images/favicon.ico" />
                <title>Pingüino App</title>
              </Head>
              <div id="modal-root"></div>
              <Layout>
                <Component {...pageProps} />
                {loading &&
                  <div style={
                    {
                      background: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'fixed',
                      width: '100%',
                      height: '100vh',
                      top: '0',
                      left: '0',
                      zIndex: '999',
                    }
                  }>
                    <Loader />
                  </div>
                }
              </Layout>
            </ModalProvider>
          </ProvideAuth>
        </ProvideGlobal>
      </>
    )
  } else {
    return <Loader />
  }
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
}

export default appWithTranslation(MyApp)