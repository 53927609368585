import axios from 'axios'

const apiUrl = process.env.NEXT_PUBLIC_API_URL

const callApi = async ({
  endpoint,
  token,
  headers,
  absUrl,
  method,
  asGet,
  noCache = false,
  ...rest
}) => {
  const config = {
    url: absUrl ? absUrl : `${apiUrl}${endpoint}`,
    headers: headers || {},
    method: method || 'GET',
    ...rest
  }

  if (method !== 'GET' && !asGet) {
    const ipResponse = await fetch('https://api.ipify.org/?format=json')
    const jsonIp = await ipResponse.json()
    config.headers['x-forwarded-for'] = jsonIp.ip
  }

  if (!noCache) {
    config.headers['Cache-Control'] = 'no-cache'
  }

  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return axios(config)
}

/* Logs */
export const getReports = async (token, { page, size, sort, logType, origin }) => {
  let params = []
  if (page) {
    params.push(`page=${page}`)
  }
  if (size) {
    params.push(`size=${size}`)
  }
  if (sort) {
    params.push(`order_date=${sort}`)
  }
  if (logType) {
    params.push(`log_type=${logType}`)
  }
  if (origin) {
    params.push(`origin=${origin}`)
  }
  if (params.length) {
    params = `?${params.join('&')}`
  }
  const response = await callApi({
    method: 'GET',
    endpoint: `admin/logs${params}`,
    token: token,
  })
  return response.data
}

/* Authorized pages */
export const getAuthorizedPages = async () => {
  const response = await callApi({
    method: 'GET',
    endpoint: 'resources/authorized_pages'
  })
  return response.data
}

export const createAuthorizedPage = async (data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `resources/authorized_pages`,
    token: token,
    data: data
  })
  return response.data
}

export const updateAuthorizedPage = async (pageId, data, token) => {
  const response = await callApi({
    method: 'PUT',
    endpoint: `resources/authorized_pages/${pageId}`,
    token: token,
    data: data
  })
  return response.data
}

export const deleteAuthorizedPage = async (pageId, token) => {
  const response = await callApi({
    method: 'DELETE',
    endpoint: `resources/authorized_pages/${pageId}`,
    token: token
  })
  return response.data
}

/* Countries */
export const getCountries = async () => {
  const response = await callApi({
    method: 'GET',
    endpoint: 'resources/countries'
  })
  return response.data
}

/* States */
export const getStates = async (country_code) => {
  const response = await callApi({
    method: 'GET',
    endpoint: `resources/countries/${country_code}/states`
  })
  return response.data
}

/* Cities */
export const getCities = async (country_code, state_code) => {
  const response = await callApi({
    method: 'GET',
    endpoint: `resources/countries/${country_code}/states/${state_code}/cities`
  })
  return response.data
}

/* Banks */
export const getBanks = async (listMode = true) => {
  const response = await callApi({
    method: 'GET',
    endpoint: `resources/banks?list_mode=${listMode}`
  })
  return response.data
}

export const createBank = async (data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `resources/banks`,
    token: token,
    data: data
  })
  return response.data
}

export const updateBank = async (id, data, token) => {
  const response = await callApi({
    method: 'PUT',
    endpoint: `resources/banks/${id}`,
    token: token,
    data: data,
  })
  return response.data
}

export const deleteBank = async (id, token) => {
  const response = await callApi({
    method: 'DELETE',
    endpoint: `resources/banks/${id}`,
    token: token
  })
  return response.data
}

/* Account Types*/
export const getBankAccountTypes = async (listMode = true) => {
  const response = await callApi({
    method: 'GET',
    endpoint: `resources/bank/accounts?list_mode=${listMode}`
  })
  return response.data
}

/* Documents types */
export const getDocumentTypes = async () => {
  const response = await callApi({
    method: 'GET',
    endpoint: 'resources/types_documents'
  })
  return response.data
}

export const createDocumentTypes = async (data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `resources/types_documents`,
    token: token,
    data: data
  })
  return response.data
}

export const updateDocumentTypes = async (id, data, token) => {
  const response = await callApi({
    method: 'PUT',
    endpoint: `resources/types_documents/${id}`,
    token: token,
    data: data
  })
  return response.data
}

export const deleteDocumentTypes = async (id, token) => {
  const response = await callApi({
    method: 'DELETE',
    endpoint: `resources/types_documents/${id}`,
    token: token,
  })
  return response.data
}


/* Legal documents */
export const getLegalDocuments = async (country = '', idCode = '', token) => {
  let params = ''
  if (country && idCode) params = `?country=${country}&identification_code=${idCode}`
  const response = await callApi({
    method: 'GET',
    endpoint: `legal/documents${params}`,
    token: token || {}
  })
  return response.data
}

export const createLegalDocument = async (data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `legal/documents`,
    token: token,
    data: data
  })
  return response.data
}

export const updateLegalDocument = async (id, data, token) => {
  const response = await callApi({
    method: 'PUT',
    endpoint: `legal/documents/${id}`,
    token: token,
    data: data,
  })
  return response.data
}

export const deleteLegalDocument = async (id, token) => {
  const response = await callApi({
    method: 'DELETE',
    endpoint: `legal/documents/${id}`,
    token: token
  })
  return response.data
}

/* Partners page */
export const getPartnersPages = async (listMode = true, token) => {
  const response = await callApi({
    method: 'GET',
    endpoint: `resources/partners_pages?list_mode=${listMode}`,
    token: token
  })
  return response.data
}

export const createPartnersPages = async (data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `resources/partners_pages`,
    token: token,
    data: data
  })
  return response.data
}

export const updatePartnersPages = async (id, data, token) => {
  const response = await callApi({
    method: 'PUT',
    endpoint: `resources/partners_pages/${id}`,
    token: token,
    data: data,
  })
  return response.data
}

export const deletePartnersPages = async (id, token) => {
  const response = await callApi({
    method: 'DELETE',
    endpoint: `resources/partners_pages/${id}`,
    token: token
  })
  return response.data
}

/* Contract */
export const generateContract = async (data) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `docusign/sign/public/contract`,
    data: data
  })
  return response.data
}

export const checkSignDone = async (contractId) => {
  const response = await callApi({
    method: 'GET',
    endpoint: `docusign/sign/public/validation?signature_id=${contractId}`,
  })
  return response.data
}

export const uploadLegalDocuments = async (fileDdata) => {
  const data = new FormData()
  data.append('legal_document_id', fileDdata[0].legal_document_id)
  fileDdata.forEach(file => {
    if (file.document) data.append('document', file.document)
  })
  const response = await callApi({
    method: 'POST',
    endpoint: `legal/documents/profile`,
    data: data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return response.data
}

export const uploadExtraDocument = async (fileDdata) => {
  const data = new FormData()
  data.append('identity_document', fileDdata.identity_document)
  data.append('document', fileDdata.document)

  const response = await callApi({
    method: 'POST',
    endpoint: `users/profile/upload/bank_document`,
    data: data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return response.data
}

export const getUsers = async (token, { platformFilter, page, size }) => {
  let params = []
  if (page) {
    params.push(`page=${page}`)
  }
  if (size) {
    params.push(`size=${size}`)
  }
  if (platformFilter) {
    params.push(`platformFilter=${platformFilter}`)
  }
  if (params.length) {
    params = `?${params.join('&')}`
  }
  const response = await callApi({
    method: 'GET',
    endpoint: `admin/users${params}`,
    token: token
  })
  return response.data
}

export const getUserPermissions = async (user_id, token) => {
  const response = await callApi({
    method: 'GET',
    endpoint: `admin/permissions/user/${user_id}`,
    token: token,
  })
  return response.data
}

export const setUserPermissions = async (data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `/admin/permissions/user/`,
    data: data,
    token: token,
  })
  return response.data
}

export const createAdminUser = async (data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `admin/add_user`,
    data: data,
    token: token,
  })
  return response.data
}

export const deleteUserPermissions = async (permissionId, token) => {
  const response = await callApi({
    method: 'DELETE',
    endpoint: `/admin/permissions/user/${permissionId}`,
    token: token
  })
  return response.data
}

export const createProfile = async (data) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `users/profile`,
    data: data,
  })
  return response.data
}

export const getProfile = async (id, token) => {
  const response = await callApi({
    method: 'GET',
    endpoint: `users/profile/${id}`,
    token: token
  })
  return response.data
}

export const getRequestContractUpdate = async (id, token) => {
  const response = await callApi({
    method: 'GET',
    endpoint: `users/profile/documents_signature_types/required/${id}`,
    token: token
  })
  return response.data
}

export const me = async (token) => {
  const response = await callApi({
    method: 'GET',
    endpoint: `users/me`,
    token: token
  })
  return response.data
}

export const updateProfile = async (profileId, data, token) => {
  const response = await callApi({
    method: 'PUT',
    endpoint: `users/profile/${profileId}`,
    token: token,
    data: data,
  })
  return response.data
}

export const setProfileStatus = async (profileId, data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `admin/status_profile/${profileId}`,
    token: token,
    data: data,
  })
  return response.data
}

export const setActiveStatus = async (profileId, data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `admin/approved_profile/${profileId}`,
    token: token,
    data: data,
  })
  return response.data
}

export const changeProfileEmail = async (data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `admin/profile/change_email`,
    token: token,
    data: data,
  })
  return response.data
}

export const requestEnrollmentLetter = async (profileId, data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `users/profile/${profileId}/request_enrollment_letter`,
    token: token,
    data: data,
  })
  return response.data
}

export const requestPayment = async (profileId, data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `users/profile/${profileId}/request_payment`,
    token: token,
    data: data,
  })
  return response.data
}

export const getUserIp = async () => {
  const ipResponse = await fetch('https://api.ipify.org/?format=json')
  const jsonIp = await ipResponse.json()
  return jsonIp?.ip
}

export const contact = async (data) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `contact/public`,
    data: data,
  })
  return response.data
}

export const emailExists = async (email) => {
  const data = {
    type: '1',
    content: email
  }
  const response = await callApi({
    method: 'POST',
    endpoint: `admin/verify_information`,
    asGet: true,
    data,
  })
  return response.data
}

export const documentExists = async (document) => {
  const data = {
    type: '2',
    content: document
  }
  const response = await callApi({
    method: 'POST',
    endpoint: `admin/verify_information`,
    asGet: true,
    data,
  })
  return response.data
}

export const sendEmail = async (data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `admin/send_email`,
    token: token,
    data: data,
  })
  return response.data
}

export const getTRM = async (date) => {
  const response = await callApi({
    method: 'GET',
    absUrl: `https://www.datos.gov.co/resource/32sa-8pi3.json?vigenciadesde=${date}`,
    headers: {
      'X-App-Token': 'MN1B62Shf3HxqF3Db9qNBAmeq',
      'Accept': 'application/json'
    },
    noCache: true,
  })
  return response.data
}

export const setTRMPercentage = async (data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `admin/configuration`,
    token: token,
    data: data,
  })

  return response.data
}

export const setProfileTRMPercentage = async (profileId, data, token) => {
  const response = await callApi({
    method: 'POST',
    endpoint: `admin/profiles/${profileId}/prime_rate`,
    token: token,
    data: data,
  })
  return response.data
}

export const getTRMPercentage = async (token) => {
  const response = await callApi({
    method: 'GET',
    endpoint: `admin/configuration`,
    token: token,
  })
  return response.data
}