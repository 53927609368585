import React, { useState, useEffect, useContext, createContext } from 'react'
import queryString from 'query-string'
import * as firebase from 'firebase/app'
import 'firebase/auth'

import { me, getAuthorizedPages, emailExists } from '@services/api'

import fbConfig from '../fb.js'

if (!firebase.apps.length) {
  firebase.initializeApp(fbConfig)
}

const authContext = createContext()

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

function useProvideAuth() {
  const [user, setUser] = useState(null)

  const signin = async (email, password) => {
    const isActiveUser = await emailExists(email)
    if (isActiveUser?.results[0]?.exists && !isActiveUser?.results[0]?.ready_profile) {
      return Promise.reject('inactive')
    }
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (response) => {
        const token = await getIdToken()
        const userInfo = await me(token)
        const userData = { ...response.user, ...userInfo.results[0] }
        setUser(userData)
        return userData
      })
  }

  const signup = (email, password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (response) => {
        setUser(response.user)
        return response.user
      })
  }

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false)
      })
  }

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true
      })
  }

  const confirmPasswordReset = (password, code) => {
    const resetCode = code || getFromQueryString('oobCode')

    return firebase
      .auth()
      .confirmPasswordReset(resetCode, password)
      .then(() => {
        return true
      })
  }

  const updateEmail = (email) => {
    return firebase
      .auth()
      .updateEmail(email)
      .then(() => {
        return true
      })
  }

  const updatePassword = (newPassword) => {
    return firebase
      .auth()
      .currentUser
      .updatePassword(newPassword)
      .then(() => {
        return true
      })
  }

  const getIdToken = () => {
    return firebase
      .auth()
      .currentUser
      .getIdToken(true)
      .then((idToken) => {
        return idToken
      })
  }

  const getMeInfo = async () => {
    const token = await getIdToken()
    me(token).then(res => {
      const userInfo = res.results[0]
      setUser(userInfo)
      return userInfo
    }).catch(() => {
      setUser(false)
      return false
    })
  }

  const getIfIsAuthorizedPage = async () => {
    let isAuthorized = false
    let authorizedPages = await getAuthorizedPages()
    authorizedPages = authorizedPages.results.map(authorizedPage => {
      return authorizedPage.page.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
    })
    const savedReferrer = sessionStorage.getItem('ap').replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
    isAuthorized = authorizedPages.includes(savedReferrer) || savedReferrer === 'TwcAu0M0n2Ppu' || savedReferrer === 'Twc4BM0n2Ppu' || process.env.NEXT_PUBLIC_ENV === 'develop'
    return isAuthorized

  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await getIdToken()
        const userInfo = await me(token)
        const userData = { ...user, ...userInfo.results[0] }
        setUser(userData)
        return userData
      } else {
        setUser(false)
      }
    })

    return () => unsubscribe()
  }, [])



  return {
    userId: user && user.uid,
    user: user,
    tokenId: user && user.refreshToken,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateEmail,
    updatePassword,
    getIdToken,
    getMeInfo,
    getIfIsAuthorizedPage
  }
}

const getFromQueryString = (key) => {
  return queryString.parse(window.location.search)[key]
}
