import React, { useState, useEffect } from 'react'

import TextField from '@material-ui/core/TextField'
import { isFieldValid } from '@utils/form'
import letters from '@utils/letters'

export const Input = ({
  autoFocus,
  children,
  defaultValue,
  disabled,
  error,
  fullWidth = true,
  helperText,
  InputLabelProps,
  inputRef,
  label,
  multiline,
  name,
  onChange,
  required,
  rows,
  select,
  value: initValue,
  type = 'text',
  validation,
 }) => {
  const [value, setValue] = useState(initValue || '')
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    onChange && onChange(name, value, hasError)
  }, [hasError])

  const handleChange = (event) => {
    let newValue = event.target.value
    if (validation === 'no-especial') {
      newValue = removeSpecialCharacters(newValue)
    }
    setValue(newValue)
    onChange && onChange(name, newValue, hasError)
  }

  const handleOnBlur = () => {
    const errorValue = isFieldValid(type, required, value)
    setHasError(!errorValue)
  }

  const removeSpecialCharacters = (val) => {
    let str = val
    const strArray = str.split('').map(letter => letters[letter] || letter)
    str = strArray.join('')
    str = str.replace(/[^a-zA-ZñÑ ]/g, '')
    return str
  }

  return (
    <div className="CommonInputContainer">
      <TextField
        autoComplete="false"
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        disabled={disabled}
        error={error || hasError}
        fullWidth={fullWidth}
        helperText={helperText}
        id={name}
        InputLabelProps={InputLabelProps}
        inputRef={inputRef}
        label={label}
        margin="normal"
        multiline={multiline}
        name={name}
        onChange={e => handleChange(e)}
        onBlur={handleOnBlur}
        required={required}
        rows={rows}
        select={select}
        type={type}
        value={value}
        variant="outlined"
      >
        {children}
      </TextField>
    </div>
  )
}

export default Input
