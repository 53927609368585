import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {useRouter} from 'next/router'
import NextLink from 'next/link'

import { withTranslation } from '@utils/i18n'
import { useAuth } from '@utils/auth'
import { getFirstWord } from '@utils/common'

import css from './index.module.scss'

import Modal from '@components/Modal'
import SignIn from '@components/Singin'
import Text from '@components/common/Text'
import Link from '@components/common/Link'

const Navbar = ({ t }) => {
  const router = useRouter()
  const { getIdToken, signout, user, getIfIsAuthorizedPage } = useAuth()

  const [loggedIn, setLoggedIn] = useState('')
  const [isAuthorizedReferrer, setIsAuthorizedReferrer] = useState(0)

  useEffect(async () => {
    if (!loggedIn && user) {
      const tokenString = await getIdToken()
      setLoggedIn(!!tokenString)
    } else if (loggedIn === '' && user === false) {
      setLoggedIn(false)
    }
  }, [user, loggedIn])

  useEffect(async () => {
    if (isAuthorizedReferrer === 0) {
      const isAuthorizedR = await getIfIsAuthorizedPage()
      setIsAuthorizedReferrer(isAuthorizedR)
    }
  }, [isAuthorizedReferrer])


  const logout = async () => {
    await signout()
    setLoggedIn(false)
    router.push('/')
  }

  return (
    <div className={css.container}>
      <nav>
        {loggedIn === false && user === false && isAuthorizedReferrer
          ? <Modal
            actionText={<Link underline="none">{t('login')}</Link>}
            width={300}
            content={<SignIn/>}
          />
          : loggedIn && user
            ? <div className={css.userData}>
                {user.status_profile === 'R' &&
                  <div className={css.statusDesc}>
                    <Text variant="caption" color="error">{t('validations:reject_message_1')}</Text>
                    <Text variant="caption" color="error">{t('validations:reject_message_2')}</Text>
                  </div>
                }
                <NextLink href={user.is_admin ? '/admin' : '/profile'}>
                  <a>
                    <img src="/images/user.png" className={css.avatar} />
                  </a>
                </NextLink>
                <div className={css.userStatus}>
                  {!user.is_admin &&
                    <>
                      <Text>{t('hi')} {getFirstWord(user.name)}</Text>
                      <Text variant="caption" style="success" color={user.status_profile === 'P' ? 'textSecondary' : user.status_profile === 'A' ? 'textPrimary' : 'error'}>
                        {user.status_profile === 'P' && t('validations:pending')}
                        {user.status_profile === 'A' && t('validations:approved')}
                        {user.status_profile === 'R' && t('validations:rejected')}
                      </Text>
                    </>
                  }
                  {user.is_admin &&
                    <>
                      <Text>{t('hi')} Admin</Text>
                      {!router.pathname.includes('admin') &&
                        <NextLink href="/admin">
                          <Link
                            color="secondary"
                            underline="always"
                            variant="caption"
                            style="success"
                          >
                            {t('go_admin')}
                          </Link>
                        </NextLink>
                      }
                    </>
                  }
                  <Link
                    color="secondary"
                    underline="always"
                    variant="caption"
                    style="success"
                    onClick={logout}
                  >
                    {t('logout')}
                  </Link>
                </div>
              </div>
            : null
        }
      </nav>
    </div>
  )
}

Navbar.getInitialProps = async () => ({
	namespacesRequired: ['common', 'validations']
})

Navbar.propTypes = {
	t: PropTypes.func.isRequired
}

export default withTranslation(['common', 'validations'])(Navbar)
