import React from 'react'
import useModal from './useModal'
import Modal from './modal'

let ModalContext
let { Provider } = (ModalContext = React.createContext())

let ModalProvider = ({ children }) => {
  let { modal, handleModal, modalContent, modalW, closeSymbol } = useModal()
  return (
    <Provider value={{ modal, handleModal, modalContent, modalW, closeSymbol }}>
      <Modal />
      {children}
    </Provider>
  )
}

export { ModalContext, ModalProvider }
