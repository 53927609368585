import React from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import RegularLayout from './RegularLayout'
import AdminLayout from './AdminLayout'

import css from './index.module.scss'

const Layout = ({ children, className }) => {
  const router = useRouter()
  const isAdminPage = router.pathname.startsWith('/admin')
  const layout = isAdminPage 
  ? <AdminLayout>{children}</AdminLayout>
  : <RegularLayout>{children}</RegularLayout>
return <div className={classNames(css.mainContainer, { [css.mainContainerAdmin]: isAdminPage }, css[className])}>{layout}</div>
}

export default Layout
