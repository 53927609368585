import React from 'react'
import Router from 'next/router'

import { Modal as MaterialModal } from '@material-ui/core/'

import css from './index.module.scss'
import Text from '@components/common/Text'
import Button from '@components/common/Button'

const CLOSE_PATH = `/images/close.png`
const closeImg = <img src={CLOSE_PATH} />
class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: props.isOpen || false,
      width: props.width || 400,
      modalStyle: {}
    }
  }

  componentDidMount() {
    this.getModalStyle()
    window.addEventListener("resize", this.getModalStyle)
    Router.events.on('routeChangeComplete', () => {
      setTimeout(() => {
        this.handleClose()
      }, 200);
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ open: this.props.isOpen })
    }
  }

  componentWillUnmount () {
    window.removeEventListener("resize", this.getModalStyle)

  }

  getModalStyle = () => {
    const pageWidth = window.innerWidth
    const calculatedLeft = Number(pageWidth / 2) - (this.state.width / 2)
    const left = calculatedLeft > 0 ? `${calculatedLeft}px` : '5%'
    this.setState({
      modalStyle: {
        top: `15%`,
        left: `${left}`,
        width: this.state.width
      }
    })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render () {
    const { actionText, content, title, closeButton, closeSymbol } = this.props
    const { open, modalStyle } = this.state
    return (
      <>
        <span onClick={this.handleOpen}>
          {actionText}
        </span>
        <MaterialModal
          open={open}
          onClose={this.handleClose}
          BackdropProps= {{
            style: {backgroundColor: 'rgba(255, 255, 255, 0.8)'}
          }}
        >
          <div style={modalStyle} className={css.container}>
            {(closeSymbol || title) &&
              <div className={css.heading}>
                {title && <div className={css.titleContainer}><Text component="h2" variant="h5">{title}</Text></div>}
            {closeSymbol && <div className={css.closeSymbolContainer} onClick={this.handleClose}>{closeImg}</div>}
              </div>
            }
            {content}
            {closeButton &&
              <div className={css.actionsContainer}>
                <Button style="primary" onClick={this.handleClose}>{closeButton}</Button>
              </div>
            }
          </div>
        </MaterialModal>
      </>
    )
  }
}

export default Modal
