import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from '@utils/i18n'
import { isFieldValid } from '@utils/form'
import { useAuth } from '@utils/auth'

import LinkNext from 'next/link'
import { useRouter } from 'next/router'

import Input from '@components/common/Input'
import Button from '@components/common/Button'
import Text from '@components/common/Text'
import Link from '@components/common/Link'

import css from './index.module.scss'

const SignIn =({ t, from, ready }) => {
  const { sendPasswordResetEmail, signin, user } = useAuth()
  const router = useRouter()

  const [formToShow, setFormToShow] = useState('login') // login, resetPass
  const [email, setEmail] = useState({ value: '', error: false })
  const [password, setPassword] = useState({ value: '', error: false })
  const [errors, setErrors] = useState([])
  const [success, setSuccess] = useState('')

  const handleChange = (name, value) => {
    const stateValue = { value: value, error: false }
    if (name === 'email') setEmail(stateValue)
    if (name === 'password') setPassword(stateValue)
  }

  const signIn = (e) => {
    e.preventDefault()
    const emailState = email.value
    const passwordState = password.value
    const errorsArray = []
    if (!isFieldValid('email', true, emailState)) {
      setEmail({ value: emailState, error: true })
      errorsArray.push(t('validations:invalid_email'))
      errorsArray.push(t('validations:required'))
    }
    if (!isFieldValid('text', true, passwordState)) {
      setPassword({ value: passwordState, error: true })
      errorsArray.push(t('validations:required'))
    }
    if (isFieldValid('email', true, emailState) && isFieldValid('text', true, passwordState)) {
      setEmail({ value: emailState, error: false })
      setPassword({ value: passwordState, error: false })
      setErrors([])
      signin(emailState, passwordState).then(async (res) => {
        if(from === 'changePass' || from === 'signContract') {
          ready && ready()
        } else {
          if (res.is_admin) {
            router.push('/admin')
          } else {
            router.push('/profile')
          }
        }
      })
      .catch((err) => {
        setErrors([err === 'inactive' ? t('validations:inactive') : t('validations:no_login')])
      })
    } else {
      setErrors(errorsArray)
    }
  }

  const resetPassword = () => {
    const emailState = email.value
    if (!isFieldValid('email', true, emailState)) {
      setEmail({ value: emailState, error: true })
      setErrorst(['validations:invalid_email'])
    } else {
      setEmail({ value: emailState, error: false })
      setErrors([])
      sendPasswordResetEmail(email.value).then(() => {
        setSuccess(t('validations:send_reset_success'))
      })
      .catch(() => {
        setErrors([t('validations:no_send_reset')])
      })
    }
  }

  const errorsArray = errors.filter((error, idx, self) => error?.length && self.indexOf(error) === idx)
  if (formToShow === 'login') {
    return (
      <div className={css.container}>
        <div className={css.heading}>
        {from === 'changePass'
          ?<Text component="h1" variant="body1">
            {t('validations:re_login')}
          </Text>
          : from === 'signContract'
            ? <Text component="p" variant="h4" align="center">
              Por favor inicia sesión para habilitar la firma del contrato
            </Text>
            : <Text component="h1" variant="h3" align="center">
                {t('welcome')}
              </Text>
        }
        </div>
        <form onSubmit={signIn} noValidate>
          <Input
            name="email"
            label={t('email')}
            autoComplete="email"
            onChange={handleChange}
            autoFocus
            value={email.value}
            error={email.error}
            required
          />
          <Input
            type="password"
            name="password"
            label={t('password')}
            onChange={handleChange}
            autoComplete="current-password"
            value={password.value}
            error={password.error}
            required
          />
          {errorsArray.map((error, idx) => {
            return (
              <div key={idx}>
                <Text component="p" color="error" variant="caption">{error}</Text>
              </div>
            )
          })}
          {from === 'changePass'
            ? <div className={css.actionsContainer}>
                <div className={css.buttonContainer}>
                  <Button
                    type="submit"
                  >
                    {t('send')}
                  </Button>
                </div>
              </div>
            : <div className={css.actionsContainer}>
                <div className={css.buttonContainer}>
                  <Button
                    color="secondary"
                    type="submit"
                    fullWidth
                  >
                    {t('login')}
                  </Button>
                </div>
                <Link onClick={() => setFormToShow('resetPass')} variant="caption">{t('forgot_password')} </Link>
                <br/>
                {from !== 'signContract' && <Text variant="caption" display="inline">
                  {t('new_user')}&nbsp;
                  <LinkNext href="/signup">
                    <Link variant="caption" color="secondary" underline="always">{t('signup')}</Link>
                  </LinkNext>
                </Text>}
              </div>
          }
        </form>
      </div>
    )
  } else if (formToShow === 'resetPass') {
    return (<div className={css.container}>
      <div className={css.heading}>
        <Text component="h1" variant="h5" align="center">
          {t('reset_pass')}
        </Text>
      </div>
      <form>
        <Input
          name="email"
          label={t('email')}
          autoComplete="email"
          onChange={handleChange}
          autoFocus
          value={email.value}
          error={email.error}
          required
        />
        {errorsArray.map((error, idx) => {
          return (
            <div key={idx}>
              <Text component="p" color="error" variant="caption">{error}</Text>
            </div>
          )
        })}
        {success &&
          <div>
            <Text component="p" color="textPrimary" variant="body1">{success}</Text>
          </div>
        }
        <div className={css.actionsContainer}>
          <div className={css.buttonContainer}>
            <Button
              color="secondary"
              fullWidth
              onClick={() => success ? setFormToShow('login') : resetPassword()}
            >
              {success ? t('back') : t('send')}
            </Button>
          </div>
        </div>
      </form>
    </div>
    )
  } else {
    return null
  }
}

SignIn.getInitialProps = async () => ({
	namespacesRequired: ['common', 'validations']
})

SignIn.propTypes = {
	t: PropTypes.func.isRequired
}

export default withTranslation(['common', 'validations'])(SignIn)