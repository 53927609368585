import CryptoJS from 'crypto-js'

export const encrypt = text => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text))
}

export const decrypt = data => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8)
}

export const transformArrayToBeListed = (array, key = null, value = null) => {
  let newArray = array
  if (key && value) {
    newArray = array.map((element) => {
      return {
        ...element,
        key: element[key],
        value: element[value],
      }
    })
  }
  return newArray
}

export const getFirstWord = (string) => {
  const stringArray = string?.split(' ')
  if (stringArray?.length > 1) {
    return stringArray[0]
  } else {
    return string
  }
}

export const pList = {
  'BA': 'Bancos',
  'TD': 'Tipos de documentos',
  'LD': 'Documentos legales',
  'AP': 'Páginas autorizadas',
  'PR': 'Perfiles',
  'US': 'Usuarios',
  'RE': 'Reportes',
  'PP': 'Páginas de pago',
  'TR': 'Tasa del día',
}
export const pListArray = ['BA', 'TD', 'LD', 'AP', 'PR', 'US', 'RE', 'PP', 'TR']

export const getCountryByCode = (countriesList, code) => {
  return countriesList.find(country => country.key === code)?.value
}

export const getTypeDocumentById = (documentTypes, id) => {
  return documentTypes.find(documentType => documentType.id === id)?.name
}

export const getTypeDocumentCodeById = (documentTypes, id) => {
  return documentTypes.find(documentType => documentType.id === id)?.code
}

export const shouldShowBrowserUpdateM = () => {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
  const chromeVersion = raw ? parseInt(raw[2], 10) : false
  if (isIE() || (chromeVersion !== false && chromeVersion <= 79)) {
    return true
  }
  return false
}

export const isIE = () => {
  const ua = window.navigator.userAgent
  const isIE = ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1
  return isIE
}