import React, { useState, useEffect } from 'react'

import Head from 'next/head'
import { useRouter } from 'next/router'

import { useAuth } from '@utils/auth'

import Header from '@components/Header'
import Footer from '@components/Footer/Footer'

import css from './regular-layout.module.scss'

const RegularLayout = ({ children }) => {
  const router = useRouter()
  const { user } = useAuth()

  const [shouldShowbeacon, setShouldShowbeacon] = useState(false)
  const [shouldSetChatUser, setShouldSetChatUser] = useState(false)

  useEffect(async () => {
    if (user) {
      setShouldSetChatUser(true)
      setShouldShowbeacon((user?.id?.length && !router.pathname.includes('admin')) || router.pathname.includes('downtime'))
    } else {
      setShouldSetChatUser(false)
      setShouldShowbeacon(false)
      window.Beacon && window.Beacon('logout')
      window.Beacon && window.Beacon('destroy')
    }
    return () => sessionStorage.removeItem('ap')
  }, [user])

  return (
    <>
      {shouldShowbeacon &&
        <Head>
          <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `(!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){}))` }} />
          <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `(window.Beacon('init', 'f3a354b7-efa8-4942-8e8d-3c5f2cfe4b0a'))` }} />
          {shouldSetChatUser && user &&
            <script type="text/javascript" dangerouslySetInnerHTML={{
              __html: `(window.Beacon('identify', {
                name: '${user?.name}',
                email: '${user?.user?.email}',
              }))` }}
            />
          }

        </Head>
      }
      <div className={css.topContainer}>
        {!router.pathname.includes('downtime') && <Header />}
        <div className={css.mainContentContainer}>{children}</div>
      </div>
      {!router.pathname.includes('downtime') && <Footer />}
    </>
  )
}

export default RegularLayout
