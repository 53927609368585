import React from 'react'

import css from './index.module.scss'

const PdfViewer = ({ doc }) => {
  return (
    <iframe className={css.iframe} src={doc}/>
  )
}

export default PdfViewer
